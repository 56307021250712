import React, {useEffect, useState} from 'react';
import styles from "./quote-preview.module.scss";
import {useParams} from "react-router-dom";
import axios from "axios";

type QuotePreviewProps = {

};

function QuotePreview(props: QuotePreviewProps) {

    const { id } = useParams();
    const [post, setPost] = useState<any>(undefined);

    useEffect(() => {
        axios.get(`/api/preview/posts/${id}`)
            .then((response) => {
                setPost(response.data);
            }).catch((error) => {
                console.log('error: ', error.response);
            });
    }, []);

    return (
        <div className={styles.QuotePreview}>
            <div className={styles.imageContainer}>
                <img
                    className={styles.image}
                    src={post?.image}
                    alt={post?.image}
                />
            </div>
        </div>
    );
}

export default QuotePreview;
