import React from 'react';

type WelcomeProps = {

}

function Welcome(props: WelcomeProps) {
    return (
        <div>
            Welcome
        </div>
    );
}

export default Welcome;
