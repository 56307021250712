import React from 'react';
import ProfilePreview from "../profile-preview/profile-preview";
import {Route} from "react-router-dom";
import Welcome from "../welcome/welcome";
import RatingPreview from "../rating-preview/rating-preview";
import QuotePreview from "../quote-preview/quote-preview";

type AppProps = {

};

function App(props: AppProps) {
    return (
        <div>
            <Route
                exact
                path="/"
                render={() => (<Welcome />)}
            />
            <Route
                exact
                path="/profilepreview/:username"
                render={() => (<ProfilePreview />)}
            />
            <Route
                exact
                path="/profilepreview/:username/:type"
                render={() => (<ProfilePreview />)}
            />
            <Route
                path="/profilepreview/:username/:type/:height"
                render={() => (<ProfilePreview />)}
            />
            <Route
                path="/ratingpreview/:id"
                render={() => (<RatingPreview />)}
            />
            <Route
                path="/quotepreview/:id"
                render={() => (<QuotePreview />)}
            />
        </div>
    );
}

export default App;
