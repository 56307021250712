import React, {useEffect, useState} from 'react';
import styles from './rating-preview.module.scss';
import {useParams} from "react-router-dom";
import axios from "axios";
import {Progress} from "antd";

type RatingPreviewProps = {

}

function RatingPreview(props: RatingPreviewProps) {
    const { id } = useParams();
    const [post, setPost] = useState<any>(undefined);

    useEffect(() => {
        axios.get(`/api/preview/posts/${id}`)
            .then((response) => {
                console.log('response: ', response.data);
                setPost(response.data);
            }).catch((error) => {
            console.log('error: ', error.response);
        });
    }, []);

    const convertRatingToColor = (rating: number | undefined): string => {
        switch (rating) {
            case 10: // diamond
                return '#30f1b7';
            case 9: // platinum
                return '#30f1b7';
            case 8: // gold
                return '#30f1b7';
            case 7: // silver
                return '#1c7ac6';
            case 6: // bronze
                return '#1c7ac6';
            case 5: // wood
                return '#f9d70c';
            case 4: // water
                return '#f9d70c';
            case 3: // tin
                return '#de752f';
            case 2: //
                return '#ff0000';
            case 1: // coronavirus
                return '#ff0000';
            default:
                return '#1c7ac6';
        }
    };

    return (
        <div className={styles.RatingPreview}>
            <div className={styles.container}>
                <div className={styles.ratingContainer}>
                    <div className={styles.leftContainer}>
                        <Progress
                            className={styles.ratingProgress}
                            width={100}
                            type="circle"
                            strokeColor={{
                                '0%': convertRatingToColor(post?.rating),
                                '100%': convertRatingToColor(post?.rating),
                            }}
                            percent={((post?.rating / 10) * 100)}
                            format={(percent) => (
                                <span>
                                    <span className={styles.ratingValue}>{post?.rating}</span>
                                    <span className={styles.outOf}>/10</span>
                                </span>
                            )}
                        />
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.imageContainer}>
                            <img
                                className={styles.image}
                                src={post?.medium?.imageUrl}
                                alt={post?.medium?.imageUrl}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RatingPreview;
