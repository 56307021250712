import React, {useEffect, useState} from 'react';
import styles from './profile-preview.module.scss';
import {useParams} from "react-router-dom";
import axios from "axios";
type ProfilePreviewProps = {

}

function ProfilePreview(props: ProfilePreviewProps) {
    const { username, type } = useParams();
    const [image1, setImage1] = useState('');
    const [image2, setImage2] = useState('');
    const [image3, setImage3] = useState('');
    const [user, setUser] = useState<any>(undefined);

    useEffect(() => {
        axios.get(`/api/preview/${username}${type ? `/${type}`:''}`)
            .then((response) => {
                console.log('response: ', response.data);
                setUser(response.data.user);

                if (response.data.images.length > 0) {
                    setImage1(response.data.images[0]);
                }

                if (response.data.images.length > 1) {
                    setImage2(response.data.images[1]);
                }

                if (response.data.images.length > 2) {
                    setImage3(response.data.images[2]);
                }
            }).catch((error) => {
            console.log('error: ', error.response);
        });
    }, [type, username]);

    return (
        <div className={styles.ProfilePreview}>
            <div className={styles.container} style={{height: '614px' }}>
                <div className={styles.profileContainer}>
                    <div className={styles.imageContainer}>
                        <img
                            className={styles.image}
                            style={{ width: '120px', height: '120px', borderRadius: '60px' }}
                            src={user?.image}
                            alt={user?.image}
                        />
                    </div>
                    <div className={styles.username}>
                        {user?.username}
                    </div>
                    <div className={styles.displayName}>
                        {user?.displayName}
                    </div>
                </div>
                <div className={styles.mediumsContainer}>
                    <div className={styles.mediums}>
                        <img
                            className={styles.image1}
                            src={image1}
                            alt={image1}
                        />
                        <img
                            className={styles.image1}
                            src={image2}
                            alt={image2}
                        />
                        <img
                            className={styles.image1}
                            src={image3}
                            alt={image3}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfilePreview;
